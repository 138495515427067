import React from 'react';
import {NewsLayout} from 'sections/News/';
import useNewsData from 'shared/Hooks/News/useNewsData';
import {Images} from 'src/sections/News/NewsLayout/Images';
import {TextBlock} from 'src/sections/News/NewsLayout/TextBlock';
import {NEWS_56} from 'types/News';
import {Gallery} from '../../sections/News/NewsLayout/Gallery';

const Article = () => {
  const data = useNewsData(NEWS_56);

  return (
    <NewsLayout data={data}>
      <Images data={data.images.first}/>
      <TextBlock data={data.texts.first}/>
      <TextBlock data={data.texts.second}/>
      <TextBlock data={data.texts.third}/>
      <Gallery data={data.gallery.first.images}/>
    </NewsLayout>
  );
};

export default Article;
